<template>
  <div class="transport-grant-performance">
    <p>
      Обращаем Ваше внимание, что необходимо загружать документы с актуальными
      данными
    </p>
    <p>
      Все документы необходимо загружать с персонального компьютера или ноутбука
      во избежание технических неполадок
    </p>
    <div
      class="transport-grant-performance__desc"
      v-if="type === 'social_need'"
    >
      <div
        class="transport-grant-performance__desc-item"
        :class="[
          { list: item.list },
          { open: item.isShown },
          { bold: item.bold },
        ]"
        v-for="(item, index) in tgSocialNeedRequirements"
        :key="index"
      >
        <span @click="item.isShown = !item.isShown">{{ item.title }}</span>
        <SmoothHeight>
          <ul v-show="item.isShown">
            <li v-for="(i, index) in item.list" :key="index">
              {{ i.point }}
            </li>
          </ul>
        </SmoothHeight>
      </div>
    </div>
    <div class="transport-grant-performance__upload">
      <DocsComponent
        :type="'education'"
        :project="true"
        :current-project="currentProject"
        :is_disabled="isDisabled || isBtnDisabled"
        :category_id="
          currentCategoryId || currentCategoryData?.info_category.id
        "
        @docs-changed="docsChanged"
        @up-docs="(value) => (educationDocs = value)"
      />
    </div>
    <div class="transport-grant-performance__upload__title" v-if="!isDisabled">
      *файлы с разрешением png, jpg размером не более 2 Мб
    </div>
    <div class="transport-grant-performance__btn">
      <Button @click="sendStatement" :loading="isBtnLoading" v-if="!isDisabled">
        {{
          this.type === "socialActive" ? "Сохранить изменения" : "Подать заявку"
        }}
      </Button>
      <Button
        @click="openPopup"
        secondary
        :loading="isDeleteBtnLoading"
        v-if="this.type === 'social_need' && rollbackAllowed"
      >
        Отказ от участия
      </Button>
      <Button secondary v-if="false">Сохранить как черновик</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import { mapGetters, mapState } from "vuex";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import moment from "moment";
import documentsLabels from "@/enums/documentsLabels";
import tgSocialNeedRequirements from "@/enums/tgSocialNeedRequirements";
import SmoothHeight from "@/components/SmoothHeight";
import DocsComponent from "@/components/Blocks/DocsComponent";
import useVuelidate from "@vuelidate/core";

export default {
  name: "ExcellentStudentPerformance",
  components: { DocsComponent, SmoothHeight, Button },
  props: ["type", "currentProject"],
  data() {
    return {
      isBtnLoading: false,
      isBtnDisabled: false,
      documentsLabels,
      tgSocialNeedRequirements,
      isFormValidate: false,
      educationDocs: [],
      errors: [],
      formData: {
        document_student_record_book_main_page: "",
        document_student_record_book_session_pages_one: "",
        document_student_record_book_session_pages_two: "",
        document_social_need: "",
        document_reference: "",
        document_certificate_personal_income_student_last_3_months: "",
        document_consent_parents: "",
        document_reference_income_parent_one: "",
        document_reference_income_parent_two: "",
        document_certificate_guardianship_adoption: "",
        document_certificate_family_composition: "",
        document_reference_scholarship_amount: "",
      },
    };
  },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  watch: {
    async user() {
      await this.loadInfo();
      this.setProjectEducationData();
    },
    docs: {
      handler: function () {
        Object.keys(this.formData).forEach((item) => {
          return (this.formData[item] = this.docs[item]);
        });
      },
      deep: true,
    },
    disabledFields: {
      handler: function () {
        this.isFormValidate = this.disabledFields["isProjectEducationFilled"];
      },
      deep: true,
    },
  },
  computed: {
    currentCourse() {
      return this.user?.info_course_level?.id;
    },
    isDisabled() {
      let boolean;
      if (Object.values(this.currentCategoryData).length > 0) {
        if (this.validPeriodForRollback) {
          if (this.currentCategoryData?.status?.key === 0) {
            boolean = false;
          } else boolean = this.currentCategoryData?.status?.key !== 35;
        } else if (this.validPeriodFor35Status) {
          boolean = this.currentCategoryData?.status?.key !== 35;
        } else return true;
      }
      return this.isOldApplication || boolean;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    statuses() {
      return JSON.parse(
        JSON.stringify(
          this.storage?.systemInfo?.list_statuses[
            `${this.currentCategoryData?.info_project?.key}`
          ] ?? []
        )
      );
    },
    currentStatus() {
      return this.statementId
        ? "0"
        : Object.keys(this.statuses).find((item) => {
            return +item === this.currentCategoryData?.status?.key;
          });
    },
    rollbackAllowed() {
      let boolean;
      if (this.validPeriodForRollback) {
        if (
          this.currentProject?.id === 1 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
        if (this.currentProject?.id === 4) {
          boolean =
            this.currentStatus === "0" ||
            this.currentStatus === "1" ||
            this.currentStatus === "50";
        }
      } else if (this.validPeriodFor35Status) {
        if (
          this.currentProject?.id === 2 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
        if (
          this.currentProject?.id === 3 &&
          !this.disabledFields.isNotStudent
        ) {
          boolean = this.currentStatus === "50";
        }
      } else boolean = false;

      return this.isOldApplication ? false : boolean;
    },
    ...mapState("user", ["user", "disabledFields"]),
    ...mapState([
      "docs",
      "currentCategoryData",
      "statementId",
      "currentCategoryId",
      "storage",
      "isOldApplication",
      "isDeleteBtnLoading",
      "storage",
    ]),
    ...mapGetters(["isAllDocsLabels", "socialDocs"]),
  },
  methods: {
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupRevokeApplication",
        isShown: true,
        props: {
          id: this.statementId
            ? this.statementId
            : this.currentCategoryData?.id,
          fromProject: true,
        },
      });
    },
    async saveInfo() {
      if (this.errors?.length > 0) {
        this.$store.dispatch("validationErrors", {
          errors: this.errors,
          fieldsInfo: this.isAllDocsLabels,
        });
        return;
      }
      let payloadObject = {};
      Object.keys(this.formData).forEach((item) => {
        if (
          (this.user?.education?.document_files[item]?.full ||
            this.user?.social_documents?.document_files[item]?.full) &&
          this.formData[item].full
        ) {
          this.formData[item] = "";
        } else {
          if (this.user.info_validation_documents.data.length > 0) {
            let infoDocument = this.user.info_validation_documents.data.find(
              (i) => {
                return i.key_document === item;
              }
            );
            if (infoDocument) {
              infoDocument.status = 0;
            }
          }
        }
        if (this.formData[item]) {
          return (payloadObject[item] = this.formData[item]);
        }
      });

      this.isBtnLoading = true;
      this.isBtnDisabled = true;
      await createRequest(requestConfigs.POSTSaveEducation, {
        jsonPayload: payloadObject,
      })
        .then(async (response) => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
          Object.keys(response.data).forEach((item) => {
            if (this.socialDocs.includes(item)) {
              this.user.social_documents.document_files[item] =
                response.data[item];
            } else
              this.user.education.document_files[item] = response.data[item];
          });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    loadInfo() {
      if (this.user.education && this.user.social_documents) {
        Object.keys(this.formData).forEach((item) => {
          if (
            this.user.education.document_files[item] &&
            this.user.education.document_files[item].thumb
          ) {
            return (this.formData[item] =
              this.user.education.document_files[item]);
          } else if (
            this.user.social_documents.document_files[item] &&
            this.user.social_documents.document_files[item].thumb
          ) {
            return (this.formData[item] =
              this.user.social_documents.document_files[item]);
          } else return (this.formData[item] = this.docs[item]);
        });
      }
      this.isFormValidate = this.disabledFields["isProjectEducationFilled"];
    },
    async sendStatement() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.isAllDocsLabels,
        });
        return;
      }
      if (!this.isFormValidate) return;

      const needValidationDocsBefore = await this.$store.dispatch(
        "user/getNeedValidationDocuments"
      );

      if (this.type === "socialActive") {
        await this.saveInfo()
          .then(async () => {
            await this.setProjectEducationData();
            const needValidationDocsResult = await this.$store.dispatch(
              "user/getNeedValidationDocuments"
            );
            if (
              needValidationDocsBefore > 0 &&
              needValidationDocsResult === 0
            ) {
              this.$store.commit("setCurrentPopup", {
                name: "PopupSendStatement",
                isShown: true,
                props: { id: this.currentCategoryData?.id },
              });
              return;
            }
            if (this.disabledFields["isProjectEducationFilled"])
              await this.$router.push({ name: "SocialActiveAchievements" });
          })
          .finally(() => {
            this.isBtnDisabled = false;
          });
      } else {
        await this.saveInfo();

        await createRequest(requestConfigs.POSTSendStatement, {
          routerPayload: {
            id: this.statementId || this.currentCategoryData.id,
          },
        })
          .then(() => {
            this.$store.commit("pushToTray", {
              text: "Заявка успешно создана",
              type: "success",
            });
            this.$store.commit("setStatementId", "");
            this.$router.push({ name: "Applications" });
          })
          .catch((error) => {
            this.$store.commit("pushToTray", {
              text: error.errors[0].error_descr,
              type: "error",
            });
          })
          .finally(() => {
            this.isBtnDisabled = false;
          });
      }
    },
    setProjectEducationData() {
      this.$store.dispatch("user/checkProjectValidation", [
        this.educationDocs
          .filter((i) => {
            return i.is_required;
          })
          .map((item) => {
            return item.key_document;
          }),
        "isProjectEducationFilled",
        "education",
      ]);
    },
    docsChanged(value, errors) {
      this.isFormValidate = value;
      this.errors = errors;
    },
  },
  async mounted() {
    await this.loadInfo();
    this.setProjectEducationData();
    Object.keys(this.formData).forEach((item) => {
      this.$store.commit("setDocument", ["", item]);
    });
  },
};
</script>

<style lang="scss"></style>
